export default function PrivacyPolicy() {

    return (
        <section>
            <div className="max-w-[65rem] mx-auto py-5">
                <div className="flex items-start justify-start">
                    <h1 className="text-2xl font-bold text-regal-blue">Privacy Policy</h1>
                </div>
                <div className="my-5">
                    <p className="text-lg text-justify">At Kavins Academy, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the
                        types of data we collect, how we use it, and the measures we take to ensure your information is secure.
                    </p>

                    <h1 className="my-2 text-xl font-semibold">1. Information We Collect</h1>
                    <p className="my-3 text-lg text-justify">Personal Information: When you make a purchase, register an account, or contact us, we may collect your name,
                        email address, phone number, and shipping address.
                    </p>
                    <p className="my-3 text-lg text-justify">Payment Information: We do not store your credit/debit card details. All payment processing is handled by
                        secure third-party services.
                    </p>
                    <p className="my-3 text-lg text-justify">Usage Data: We collect information about your interactions with our website, including IP address, browser type,
                        and browsing behavior.
                    </p>

                    <h1 className="text-xl font-semibold">2. How We Use Your Information</h1>
                    <p className="my-3 text-lg text-justify">Order Processing: To fulfill your orders, deliver products, and provide customer support.</p>
                    <p className="my-3 text-lg text-justify">Communication: To send order confirmations, shipping notifications, and promotional offers (with your consent).</p>
                    <p className="my-3 text-lg text-justify">Improvement: To analyze user behavior and improve our website&apos;s functionality and content.</p>

                    <h1 className="text-xl font-semibold">3. Information Sharing</h1>
                    <p className="my-3 text-lg text-justify">Third-Party Services: We may share your information with third-party services for payment processing, shipping, 
                        and email marketing. These services are bound by confidentiality agreements and cannot use your data for any other purpose.
                    </p>
                    <p className="my-3 text-lg text-justify">Legal Requirements: We may disclose your information if required by law or to protect our rights and property.</p>

                    <h1 className="text-xl font-semibold">4. Data Security</h1>
                    <p className="my-3 text-lg text-justify">We implement industry-standard security measures to protect your personal information. However, no method of 
                        transmission over the internet is 100% secure, and we cannot guarantee absolute security.
                    </p>

                    <h1 className="text-xl font-semibold">5. Your Rights</h1>
                    <p className="my-3 text-lg text-justify">Access and Correction: You can access and update your personal information by logging into your account.</p>
                    <p className="my-3 text-lg text-justify">Opt-Out: You can opt out of marketing communications by following the unsubscribe link in our emails.</p>

                    <h1 className="text-xl font-semibold">6. Changes to This Policy</h1>
                    <p className="my-3 text-lg text-justify">
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.
                    </p>

                    <h1 className="text-xl font-semibold">7. Contact Us</h1>
                    <p className="my-3 text-lg text-justify">If you have any questions about this Privacy Policy, please contact us at:</p>
                    <ol className="space-y-1">
                        <li>Email :kavins.enquiry@gmail.com</li>
                        <li>Mobile :9047127777, 9791551876</li>
                        <li>Address :No. 22 / C / 8, Shiva plaza, First floor, Opp. to Sarada College (Near Pillar 49), Fairlands, Salem - 636016</li>
                    </ol>
                </div>
            </div>
        </section>
    )
}