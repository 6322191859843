export default function TermsandConditions() {

    return (
        <section>
            <div className="max-w-[65rem] mx-auto py-5">
                <div className="flex items-start justify-start">
                    <h1 className="text-2xl font-bold text-regal-blue">Terms and Conditions</h1>
                </div>
                <div className="my-5">
                    <h1 className="text-xl font-semibold">1. Introduction</h1>
                    <p className="my-3 text-lg text-justify">Welcome to Kavins Academy. By accessing or using our website, you agree to be bound by these Terms and Conditions,
                        as well as our Privacy Policy. If you do not agree with any part of these terms, you must not use our services.
                    </p>

                    <h1 className="text-xl font-semibold">2. Product Availability and Pricing</h1>
                    <p className="my-3 text-lg text-justify">Availability: All products are subject to availability, and we reserve the right to limit or discontinue any product at any time.</p>
                    <p className="my-3 text-lg text-justify">Pricing: Prices are subject to change without notice. We strive to ensure accuracy, but errors may occur. If we discover a pricing error, we will contact you to confirm the order at the correct price or cancel it.</p>

                    <h1 className="text-xl font-semibold">3. Orders and Payment</h1>
                    <p className="my-3 text-lg text-justify">Order Acceptance: An order is considered accepted once you receive a confirmation email from us.</p>
                    <p className="my-3 text-lg text-justify">Payment: We accept all UPI payment methods . Payment is due at the time of purchase.</p>

                    <h1 className="text-xl font-semibold">4. Shipping and Delivery</h1>
                    <p className="my-3 text-lg text-justify">We will ship your order to the address provided during checkout. Delivery times are estimates and 
                        may vary depending on location and shipping method.
                    </p>

                    <h1 className="text-xl font-semibold">5. Returns and Refunds</h1>
                    <p className="my-3 text-lg text-justify">Please refer to our Refund and Cancellation Policy for details on returns and refunds.</p>

                    <h1 className="text-xl font-semibold">6. Intellectual Property</h1>
                    <p className="my-3 text-lg text-justify">All content on our website, including text, images, logos, and software, is the property of 
                        Kavins Academy or our content suppliers. You may not use any content without our express written permission.
                    </p>
                    <h1 className="text-xl font-semibold">7. Limitation of Liability</h1>
                    <p className="my-3 text-lg text-justify">Kavins Academy is not liable for any direct, indirect, incidental, or consequential damages arising from your use 
                        of our website or products.
                    </p>
                    <h1 className="text-xl font-semibold">8. Governing Law</h1>
                    <p className="my-3 text-lg text-justify">These Terms and Conditions are governed by the laws of Tamil Nadu, India, without regard to its conflict of law principles.</p>

                    <h1 className="text-xl font-semibold">9. Changes to Terms and Conditions</h1>
                    <p className="my-3 text-lg text-justify">We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately 
                        upon posting on our website. It is your responsibility to review the Terms and Conditions regularly to stay informed of any updates.
                    </p>
                    <h1 className="text-xl font-semibold">11. Contact Information</h1>
                    <p className="my-3 text-lg text-justify">If you have any questions or concerns about these Terms and Conditions, please contact us at:</p>
                    <ol className="space-y-1">
                        <li>Email :kavins.enquiry@gmail.com</li>
                        <li>Mobile :9047127777, 9791551876</li>
                        <li>Address :No. 22 / C / 8, Shiva plaza, First floor, Opp. to Sarada College (Near Pillar 49), Fairlands, Salem - 636016</li>
                    </ol>
                </div>

            </div>
        </section>
    )
}