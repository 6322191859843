import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "../core/Main/Main";
import HomePage from "../components/HomePage/Homepage";
import Aboutus from "../shared/components/Home/Aboutus";
import Ourgallery from "../shared/components/Home/Ourgallery";
import ApplicationformPage from "../components/ApplicationformPage/Applicationformpage";
import Studymaterials from "../shared/components/Home/StudyMaterials";
import Loginform from "../shared/components/Loginform/Loginform";
import ProtectedRoute from "../shared/services/token/ProtectedRoute";
import AdminRouter from "../admin/AdminRouter/Adminrouter";
import TermsandConditions from "../shared/components/Pages/Termsandconditions";
import PrivacyPolicy from "../shared/components/Pages/PrivacyPolicy";
import Refundpolicy from "../shared/components/Pages/Refundpolicy";

export default function Approuter() {

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Main />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/aboutus" element={<Aboutus />} />
                    <Route path="/gallery" element={<Ourgallery />} />
                    <Route path="/application-form" element={<ApplicationformPage />} />
                    <Route path="/study-materials" element={<Studymaterials />} />
                    <Route path="/login" element={<Loginform />} />
                    <Route path="/terms-and-conditions" element={<TermsandConditions/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/refund-cancellation-policy" element={<Refundpolicy/>}/>

                </Route>
                <Route
                    path="/dashboard/*"
                    element={
                        <ProtectedRoute allowedRoles={['Admin']['Student']}>
                            <AdminRouter />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}
