/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin,  } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Dialog } from 'primereact/dialog';
import { useEffect } from 'react';

export default function Pdfview({pdfvisible,setpdfvisible,pdfdata}) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
    
        // sidebarTabs: (defaultTabs) => [
        //     defaultTabs[0],
        //     defaultTabs[1],
        //     defaultTabs[2],
        // ],

        toolbarPlugin: {
            zoomPlugin: true, 
            pageNavigationPlugin:true
        },
        renderToolbar: (Toolbar) => {
          
        }
    });

    useEffect(() =>{
        const disableTextSelection = () =>{
            document.addEventListener('selectstart' , (e) => e.preventDefault());
        };

        const disableRightClick = () =>{
            document.addEventListener('contextmenu' , (e)=> e.preventDefault());
        };

        const disableCopyPaste = (e) =>{
            if (e.ctrlKey && (e.key === 'c' || e.key === 'p')) {
                e.preventDefault();
            }
        };

        disableTextSelection();
        disableRightClick();
        document.addEventListener('keydown' ,disableCopyPaste);

        return () =>{
            document.addEventListener('selectstart' , (e) => e.preventDefault());
            document.addEventListener('contextmenu' , (e)=> e.preventDefault());
            document.addEventListener('keydown' ,disableCopyPaste);

        }

    },[])
  return (
    <div>
        <Dialog visible={pdfvisible} onHide={() => {setpdfvisible(false) }}
            style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} maximizable>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
           {!pdfdata.isloading?<Viewer fileUrl={`http://192.168.0.26:5000/stream/apigetcoursepdf?_id=${pdfdata._id}`} plugins={[defaultLayoutPluginInstance,]}/>:'Loading...'}
           </Worker>
        </Dialog>
    </div>
  )
}
