import { Tooltip } from "@nextui-org/react";
import { NavLink } from "react-router-dom";
import useAuth from "../../shared/services/store/useAuth";

export default function Sidebar() {

    const { userdetails } = useAuth();
    return (
        <>
            <div className="sticky inset-x-0 top-0 z-20 px-4 bg-white border-y sm:px-6 md:px-8 lg:hidden ">
                <div className="flex items-center justify-between py-2">
                    <ol className="flex items-center ms-3 whitespace-nowrap">
                        <li className="flex items-center text-sm text-gray-800 ">
                            Application
                            <svg className="flex-shrink-0 mx-3 overflow-visible size-2.5 text-gray-400 " width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </li>
                        <li className="text-sm font-semibold text-gray-800 truncate " aria-current="page">
                            Dashboard
                        </li>
                    </ol>
                    <button type="button" className="py-2 px-3 flex justify-center items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 text-gray-500 hover:text-gray-600 " data-hs-overlay="#application-sidebar" aria-controls="application-sidebar" aria-label="Sidebar">
                        <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M17 8L21 12L17 16M3 12H13M3 6H13M3 18H13" /></svg>
                        <span className="sr-only">Sidebar</span>
                    </button>
                </div>
            </div>

            <div id="application-sidebar" className="hs-overlay [--auto-close:lg] hs-overlay-open:translate-x-0
                -translate-x-full transition-all duration-300 transform
                w-[80px]
                hidden
                fixed inset-y-0 start-0 z-[60]
                bg-secondary border-e border-secondary
                lg:block lg:translate-x-0 lg:end-auto lg:bottom-0
                ">
                <div className="flex flex-col items-center justify-center px-2 pt-4 text-center">
                    <img src="/images/logo/favicon.png" className="border h-[50px] rounded-2xl" alt="" />
                    <h2 className="mb-1 font-semibold text-white text-md">Kavins</h2>
                </div>
                <nav className="flex flex-col flex-wrap w-full py-4 hs-accordion-group" data-hs-accordion-always-open>

                    <ul className="space-y-2.5 font-light">
                    {(userdetails?.Role === "Admin" ) && (
                        <li>
                            <Tooltip content="Dashboard" placement="right-end" showArrow={true}>
                                <NavLink to="/dashboard/home" className={({ isActive }) => `flex items-center gap-x-3 py-1 ${isActive ? 'text-[#F5C231]' : 'text-white'} p-8  mb-5 transition-all hover:text-[#F5C231]`}>

                                    <i className="mt-1 text-xl fi fi-rr-apps"></i>

                                </NavLink>
                            </Tooltip>
                        </li>
                    )}
                    {(userdetails?.Role === "Student") && (
                        <li>
                            <Tooltip content="Dashboard" placement="right-end" showArrow={true}>
                                <NavLink to="/dashboard/studenthome" className={({ isActive }) => `flex items-center gap-x-3 py-1 ${isActive ? 'text-[#F5C231]' : 'text-white'} p-8  mb-5 transition-all hover:text-[#F5C231]`}>

                                    <i className="mt-1 text-xl fi fi-rr-apps"></i>

                                </NavLink>
                            </Tooltip>
                        </li>
                    )}
                     {userdetails?.Role === "Admin"  && (
                        <li>
                            <Tooltip content="Applications" placement="right-end" showArrow={true}>
                                <NavLink to="/dashboard/applications" className={({ isActive }) => `flex items-center gap-x-3 py-1 ${isActive ? 'text-[#F5C231]' : 'text-white'} p-8  mb-5 transition-all hover:text-[#F5C231]`}>

                                <i className="mt-1 text-xl fi fi-sr-form"></i>

                                </NavLink>
                            </Tooltip>
                        </li>
                    )}
                    {userdetails?.Role === "Admin"  && (
                        <li>
                            <Tooltip content="Students" placement="right-end" showArrow={true}>
                                <NavLink to="/dashboard/students" className={({ isActive }) => `flex items-center gap-x-3 py-1 ${isActive ? 'text-[#F5C231]' : 'text-white'} p-8  mb-5 transition-all hover:text-[#F5C231]`}>

                                    <i className="mt-1 text-xl fi fi-ss-user-graduate"></i>

                                </NavLink>
                            </Tooltip>
                        </li>
                    )}
                    {userdetails?.Role === "Admin" && (
                        <li>
                            <Tooltip content="courses" placement="right-end" showArrow={true}>
                                <NavLink to="/dashboard/courses" className={({ isActive }) => `flex items-center gap-x-3 py-1 ${isActive ? 'text-[#F5C231]' : 'text-white'} p-8 mb-5 transition-all hover:text-[#F5C231]`}>

                                    <i className="mt-1 text-xl fi fi-sr-graduation-cap"></i>
                                </NavLink>
                            </Tooltip>
                        </li>
                    )}
                    {userdetails?.Role === "Admin" && (
                        <li>
                            <Tooltip content="Class Details" placement="right-end" showArrow={true}>
                                <NavLink to="/dashboard/onandupcomeclass" className={({ isActive }) => `flex items-center gap-x-3 py-1 ${isActive ? 'text-[#F5C231]' : 'text-white'} p-8  mb-5 transition-all hover:text-[#F5C231]`}>

                                <i className="mt-1 text-xl fi fi-sr-users-class"></i>

                                </NavLink>
                            </Tooltip>
                        </li>
                    )}
                     {userdetails?.Role === "Student" && (
                        <li>
                            <Tooltip content="View Courses" placement="right-end" showArrow={true}>
                                <NavLink to="/dashboard/studentcourses" className={({ isActive }) => `flex items-center gap-x-3 py-1 ${isActive ? 'text-[#F5C231]' : 'text-white'} p-8  mb-5 transition-all hover:text-[#F5C231]`}>

                                <i className="mt-1 text-xl fi fi-ss-lesson"></i>

                                </NavLink>
                            </Tooltip>
                        </li>
                    )}
                    </ul>
                </nav>
            </div>
        </>
    )
}
