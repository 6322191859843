/* eslint-disable react/prop-types */
import { Dialog } from "primereact/dialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Addanndeditform(props) {

    const { visible, setVisible, formdata, handlechange, handlesave, handleupdate } = props;

    const handleQuillChange = (value) => {
        handlechange({
            target: {
                name: 'Description',
                value: value,
            },
        });
    };
    

    return (
        <>
            <Dialog
                header="Classes Form"
                visible={visible}
                onHide={() => { if (!visible) return; setVisible(false); }}
                style={{ width: '50vw' }}
                breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                maximizable
            >
                <form onSubmit={!formdata._id ? handlesave : handleupdate}>
                    <div className="grid grid-cols-1 gap-y-2">

                        <div>
                            <div>
                                <label>Class Type</label>
                            </div>
                            <select
                                name="ClassType"
                                value={formdata['ClassType']}
                                className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                                onChange={handlechange}
                                required
                            >
                                <option disabled selected>---Select a Class---</option>
                                <option>Ongoing</option>
                                <option>Upcoming</option>
                            </select>
                        </div>
                        <div>
                            <div>
                                <label>Year</label>
                            </div>
                            <input
                                type="text"
                                name="Year"
                                value={formdata['Year']}
                                className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                                onChange={handlechange}
                                required
                            />
                        </div>
                        <div>
                            <div>
                                <label>Date</label>
                            </div>
                            <input
                                type="date"
                                name="Date"
                                value={formdata['Date']}
                                className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                                onChange={handlechange}
                            />

                        </div>
                        <div>
                            <div>
                                <label>Couse Title</label>
                            </div>
                            <input
                                type="text"
                                name="Couse_Title"
                                value={formdata['Couse_Title']}
                                className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                                onChange={handlechange}
                                required
                            />
                        </div>
                        <div>
                            <div>
                                <label>Program Name</label>
                            </div>
                            <input
                                type="text"
                                name="Program_Name"
                                value={formdata['Program_Name']}
                                className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                                onChange={handlechange}
                                required
                            />
                        </div>
                        <div>
                            <div>
                                <label>Description</label>
                            </div>
                            <ReactQuill
                                theme="snow"
                                value={formdata['Description']}
                                onChange={handleQuillChange}
                                className="mt-1"
                                required
                            />
                        </div>
                       
                        <div>
                            <div>
                                <label>Status</label>
                            </div>
                            <select
                                name="Status"
                                value={formdata['Status']}
                                className="w-full px-4 py-2 mt-1 border outline-none rounded-xl"
                                onChange={handlechange}
                                required
                            >
                                <option disabled selected>---Select Status---</option>
                                <option>Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                    </div>

                    <div className="flex justify-end gap-5 mt-5">
                        <button type="button" onClick={() => setVisible(false)} >
                            Cancel
                        </button>
                        <button
                            color="primary"
                            type="submit"
                            className="px-4 py-2 text-white bg-secondary rounded-xl"
                        >
                            <i className="fi fi-br-check"></i> {!formdata._id ? 'Save' : 'Update'}
                        </button>
                    </div>
                </form>
            </Dialog>
        </>
    )
}