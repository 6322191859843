
import useAuth from "../../../../shared/services/store/useAuth";


export default function Dashboard() {
    const { userdetails } = useAuth();
   

    const displayName = userdetails.Role === "Admin" ? "Admin" : '';

    return (
        <div className='grid justify-center grid-cols-1 mb-5 shadow-xl'>
            <div className='bg-white rounded-xl'>
                <div className='grid grid-cols-1 lg:grid-cols-2'>
                    <div className='flex items-center justify-center p-2'>
                        <p className='text-2xl font-medium lg:text-4xl text-slate-600'>
                            Welcome {displayName}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
