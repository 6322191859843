import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (location.hash === "#contact-us") {
      setTimeout(() => {
        const element = document.getElementById("contact-us");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const closeDropdown = (e) => {
    // Close dropdown if clicking outside of it
    if (e.target.closest("#hs-dropdown") === null) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  useEffect(() => {
    const sectionIds = document.querySelectorAll('a.my-link');
    const mobileMenu = document.getElementById("navbar-collapse-with-animation");

    sectionIds.forEach(link => {
      link.addEventListener("click", () => {
        mobileMenu.classList.remove("block");
        mobileMenu.classList.add("hidden");
      });
    });

    return () => {
      sectionIds.forEach(link => {
        link.removeEventListener("click", () => {
          mobileMenu.classList.remove("block");
          mobileMenu.classList.add("hidden");
        });
      });
    };
  }, []);

  return (
    <header className="sticky top-0 z-50 flex flex-wrap w-full py-5 text-sm bg-white border-b sm:justify-start sm:flex-nowrap sm:py-0">
      <nav className="relative overflow-hidden max-w-[110rem] w-full mx-auto px-4 sm:flex sm:items-center justify-center sm:justify-between sm:px-6 lg:px-8" aria-label="Global">
        <div className="flex items-center justify-between">
          <a className="flex-none text-xl font-semibold" href="" aria-label="Brand">
            <img src="/images/logo/KAVINS ACADEMY logo.png" alt="" className="w-auto h-10 lg:h-12" />
          </a>
          <div className="sm:hidden">
            <button id="mobile-menu" type="button" className="inline-flex items-center justify-center gap-2 p-2 text-sm font-medium text-gray-700 align-middle transition-all bg-white border rounded-md shadow-sm hs-collapse-toggle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
              <svg className="w-4 h-4 hs-collapse-open:hidden" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
              <svg className="hidden w-4 h-4 hs-collapse-open:block" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
            </button>
          </div>
        </div>
        <div id="navbar-collapse-with-animation" className="hidden overflow-hidden transition-all duration-300 hs-collapse basis-full grow sm:block">
          <div className="flex flex-col mt-5 gap-y-4 gap-x-0 sm:flex-row sm:items-center sm:justify-center sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7">
            <div className="flex flex-col my-header-nav gap-y-4 sm:flex-row sm:items-center sm:justify-center gap-x-2 sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7 sm:ml-auto">
              <Link className="text-xl font-semibold text-gray-800 hover:text-regal-yellow sm:py-6 my-link" data-scroll to="/" onClick={scrollToTop}>Home</Link>
              <Link className="text-xl font-semibold text-gray-800 hover:text-regal-yellow sm:py-6" to="/aboutus" onClick={scrollToTop}>About Us</Link>
              <Link className="text-xl font-semibold text-gray-800 hover:text-regal-yellow sm:py-6" to="/gallery" onClick={scrollToTop}>Gallery</Link>
              <div id="hs-dropdown" className={`hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] [--auto-close:outside] ${isDropdownOpen ? 'open' : ''}`}>
                <button id="hs-mega-menu-basic-dr" type="button" className="flex items-center w-full text-xl font-semibold text-gray-800 hover:text-regal-yellow m:py-6 my-link" onClick={toggleDropdown}>
                  Students Corner
                  <svg className="w-4 h-4 ml-2 text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path>
                  </svg>
                </button>
                <div id="dropdownu-list" className={`hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] ${isDropdownOpen ? 'hs-dropdown-open:opacity-100' : 'hidden'} sm:w-auto z-10 bg-white sm:shadow-md rounded-lg p-2 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full`}>
                  <Link className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-lg text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500" to="/study-materials">
                    Study Materials
                  </Link>
                </div>
              </div>
              <Link className="text-xl font-semibold text-gray-800 hover:text-regal-yellow sm:py-6 my-link" data-scroll to="/#contact-us">Contact Us</Link>
            </div>
            <div className="flex gap-x-3">
            <div className="flex items-center gap-x-2 sm:ml-auto">
              <Link onClick={scrollToTop} data-scroll className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" to="/login">
                Login
              </Link>
            </div>
            <div className="flex items-center gap-x-2 sm:ml-auto">
              <Link onClick={scrollToTop} data-scroll className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" to="/application-form">
                Apply Now
              </Link>
            </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
