/* eslint-disable no-undef */
import { useEffect } from "react";
import Swiper from "swiper";
import 'swiper/css';

export default function Studymaterials() {

    useEffect(() => {
        new Swiper(".bookslider", {
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            autoplay: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }, []);

    useEffect(() => {
        const buymaterialmodal = (name) => {
            document.getElementById('Requested Material').value = name;
        }

        const form1 = document.getElementById('buymaterialform');
        const animbutton = document.getElementById('animbutton');
        form1.addEventListener('submit', async event => {
            event.preventDefault();
            animbutton.innerText = 'Submitting.....';
            animbutton.setAttribute('disabled', true);

            const data = new FormData(form1);
            data.append("Sheetname", "buymaterialform");

            const formDataJSON = Array.from(data).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

            try {
                const res = await fetch(
                    'https://script.google.com/macros/s/AKfycbwzXtIRWCcl_YeBCufWRB0FpPTQvZmR7ZFDK4wmO9sDQqaMd-bfqKI6Yv4tQq2zxLot3w/exec',
                    {
                        method: 'POST',
                        body: JSON.stringify(formDataJSON),
                    },
                );

                const resData = await res.json();
                animbutton.removeAttribute('disabled');
                animbutton.innerText = 'Submit';
                if (resData.result === "Insertion successful") {
                    const button = document.getElementById('closeform');
                    button.click();
                    form1.reset();

                    alert('Your submission has been received successfully, and our expert will contact you soon.');
                } else {
                    alert("Try again later!");
                }
            } catch (err) {
                alert("Try again later!");
                console.log(err.message);
            }
        });

        // Assign the function to the window object to be accessible globally
        window.buymaterialmodal = buymaterialmodal;

    }, []);

    return (
        <>
            <section className="bg-[url('/images/about/bg-img.jpg')] bg-center bg-no-repeat overflow-hidden">
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-17 mx-auto">
                    <div className="grid grid-cols-1 gap-6 text-center md:gap-6">
                        <div>
                            <h1 className="mb-3 text-3xl font-extrabold text-white lg:text-5xl">Study Materials</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="max-w-[75rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-12 mx-auto">
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 md:gap-6">
                        {/* Book Slider Component */}
                        {[
                            { name: 'SRB/DRB Material', img: '/images/books/DRB-SRB.png', img2: '/images/books/Last-Page.png' },
                            { name: 'General Studies', img: '/images/books/gs.png', img2: '/images/books/Last-Page.png' },
                            { name: 'SRB/DRB Question Bank', img: '/images/books/Qb.png', img2: '/images/books/Last-Page.png' },
                            { name: 'SRB/DRB Book', img: '/images/books/4/1.jpg', img2: '/images/books/4/7.jpg', additionalImgs: ['/images/books/4/2.jpg', '/images/books/4/3.jpg', '/images/books/4/4.jpg', '/images/books/4/5.jpg', '/images/books/4/6.jpg', '/images/books/4/back.jpg'] },
                        ].map((book, index) => (
                            <div className="border rounded-md" key={index}>
                                <div>
                                    <div className="w-full h-full swiper bookslider">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <img src={book.img} className="w-full h-full rounded-t-md" />
                                            </div>
                                            {book.additionalImgs?.map((imgSrc, i) => (
                                                <div className="swiper-slide" key={i}>
                                                    <img src={imgSrc} className="w-full h-full rounded-t-md" />
                                                </div>
                                            ))}
                                            <div className="swiper-slide">
                                                <img src={book.img2} className="w-full h-full rounded-t-md" />
                                            </div>
                                        </div>
                                        <div className="swiper-button-next"></div>
                                        <div className="swiper-button-prev"></div>
                                    </div>
                                </div>
                                <div className="flex justify-center rounded-b-md bg-[#292764] p-3 text-white">
                                    <div
                                        className="text-xl cursor-pointer"
                                        onClick={() => buymaterialmodal(book.name)}
                                        data-hs-overlay="#hs-vertically-centered-modal">
                                        Buy Now
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <button id="to-top-button" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} title="Go To Top"
                className="fixed z-50 hidden p-4 text-lg font-semibold text-white transition-colors duration-300 border-0 rounded-full shadow-md bottom-5 right-5 w-14 h-14 bg-regal-blue hover:bg-regal-yellow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
                </svg>
            </button>
            <div id="hs-vertically-centered-modal" className="hs-overlay hidden size-full fixed top-0 start-0 !z-[50] overflow-x-hidden overflow-y-auto ">
                <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
                    <div className="flex flex-col w-full bg-white border shadow-sm rounded-xl ">
                        <div className="flex items-center justify-between px-4 py-3 ">
                            <h3 className="text-xl font-bold text-gray-800 ">
                                Buy Study Materials
                            </h3>
                            <button type="button" className="flex items-center justify-center text-sm font-semibold text-gray-800 border border-transparent rounded-full size-7 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-vertically-centered-modal">
                                <span className="sr-only">Close</span>
                                <svg className="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                            </button>
                        </div>
                        <div className="p-4 overflow-y-auto">
                            <form id="buymaterialform">
                                <div className="flex gap-1 mb-2">
                                    <input id="Requested Material" name="Requested Material" type="text" className="w-full" />
                                </div>
                                <div className="mb-2">
                                    <label>Full Name</label>
                                    <input name="Name" type="text" className="w-full px-4 py-2 border rounded-lg outline-none" required />
                                </div>
                                <div>
                                    <label>Mobile Number</label>
                                    <input name="Mobile Number" type="text" className="w-full px-4 py-2 border rounded-lg outline-none" required />
                                </div>
                                <div className="flex items-center justify-end px-4 py-3 rounded-b-lg gap-x-2 bg-gray-50 ">
                                    <button type="button" id="closeform" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " data-hs-overlay="#hs-vertically-centered-modal">Cancel</button>
                                    <button id="animbutton" type="submit" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
