import axios from "axios";
import apiurl from "../apiendpoint/apiendpoint";
import { gettoken } from "../../../../shared/services/token/token";

export const apigetallcourse = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/course/apigetallcourse`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apisavecourse = async (datas) => {
    try {
        const formData = new FormData();
        for (const key in datas) {
            formData.append(key, datas[key]);
        }
        const token = gettoken();
        const res = await axios.post(`${apiurl()}/course/apisavecourse`, formData, { headers: { "Authorization": `Bearer ${token}` }});

        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apiupdatecourse = async (_id,datas) => {
    try {
        const token = gettoken();
        const res = await axios.put(`${apiurl()}/course/apiupdatecourse/${_id}`, datas,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};
export const apideletecourse = async (_id) => {
    try {
        const token = gettoken();
        const res = await axios.delete(`${apiurl()}/course/apideletecourse/${_id}`,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apigetallclassforadd = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/course/apigetcourseforadd`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};
