import { useEffect } from "react"
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.min.js';
import Coursesoffered from "./coursesoffered";
import { Tooltip } from "@nextui-org/react";


export default function Home() {

    useEffect(()=>{
        const form = document.getElementById('quickenquiryform');

form.addEventListener('submit', async event => {
  event.preventDefault();

  const data = new FormData(form);
  data.append("Sheetname","quickenquiryform");
  const formDataJSON = Array.from(data).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
  try {
    const res = await fetch(
      'https://script.google.com/macros/s/AKfycby8rf-o6N8md2IYk4rORkZSeW2x7IkXqgAU8wuCNjh3KC_v_dW28fDRBPx8bdaXsec4/exec',
      {
        method: 'POST',
        body: JSON.stringify(formDataJSON),
      },
    );

    const resData = await res.json();
    if(resData.result=="Insertion successful"){
      form.reset();
        alert('Your submission has been received successfully, and our expert will contact you soon.');
    }
    else{
        alert("Try again later!")
    }

  } catch (err) {
    alert("Try again later!")
    console.log(err.message);
  }

 
});

const form1 = document.getElementById('joinnowform');

    form1.addEventListener('submit', async event => {
    event.preventDefault();
  
    const data = new FormData(form1);
    data.append("Sheetname","joinnowform");
  
    const formDataJSON = Array.from(data).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    
      try {
        const res = await fetch(
          'https://script.google.com/macros/s/AKfycby8rf-o6N8md2IYk4rORkZSeW2x7IkXqgAU8wuCNjh3KC_v_dW28fDRBPx8bdaXsec4/exec',
          {
            method: 'POST',
            body: JSON.stringify(formDataJSON),
          },
        );
    
        const resData = await res.json();
        if(resData.result=="Insertion successful"){
           form1.reset();
            alert('Your submission has been received successfully, and our expert will contact you soon.');
        }
        else{
            alert("Try again later!")
        }
       
      } catch (err) {
        alert("Try again later!")
        console.log(err.message);
      }
  
   
  });


    },[])

    useEffect(() => {
        const $slider = $('.slider');
        
        $slider.slick({
            autoplay: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    
        return () => {
            if ($slider.hasClass('slick-initialized')) {
                $slider.slick('unslick');
            }
        };
    }, []);

    useEffect(() => {

       const $slider = $('.slider1');
       $slider.slick({
            centerMode: true,
        
            autoplay: true,
             dots:true,
               infinite: true,
               slidesToShow: 3,
               slidesToScroll:1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                      arrows: false,
                      centerMode: true,
              
                      slidesToShow: 2
                      
                    }
                  },
              {
                breakpoint: 600,
                settings: {
                  arrows: false,
                  centerMode: false,
        
                
                  slidesToShow: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: false,   
                  slidesToShow: 1
                }
              }
            ]
        })
    
        return () => {
            if ($slider.hasClass('slick-initialized')) {
                $slider.slick('unslick');
            }
        };
    }, []);

    useEffect(() => {
        const toTopButton = document.getElementById("to-top-button");
    
        const handleScroll = () => {
          if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            toTopButton.classList.remove("hidden");
          } else {
            toTopButton.classList.add("hidden");
          }
        };
    
        const handleClick = () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        };
    
        if (toTopButton) {
          window.addEventListener("scroll", handleScroll);
          toTopButton.addEventListener("click", handleClick);
        }
        return () => {
          window.removeEventListener("scroll", handleScroll);
          if (toTopButton) {
            toTopButton.removeEventListener("click", handleClick);
          }
        };
      }, []);
    

    return (
        <>
            <section className="py-20 lg:py-44 bg-[url('./images/banner-bg-1.png')]  bg-repeat-round bg-regal-light relative overflow-hidden" id="home">
                <div className="absolute -right-28 -top-24 animate-[spin_10s_linear_infinite] hidden lg:block overflow-hidden"><img src="/images/shape/slide-shape-8.svg" alt="" /></div>
                <div className="absolute top-32 lg:top-22 2xl:top-32 right-[15%] 2xl:right-[15%] lg:right-[5%] hidden lg:block overflow-hidden">
                    <img src="/images/shape/slide-shape-4.svg" alt="" />
                </div>
                <div className="absolute overflow-hidden top-10 right-[40%] animate-jump animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both  hidden lg:block"><img src="/images/shape/slide-shape-6.svg" alt="" /></div>
                <div className="absolute overflow-hidden bottom-10 right-[40%]   hidden lg:block"><img src="/images/shape/slide-shape-3.svg" alt="" /></div>
                <div className="absolute overflow-hidden -right-48 -bottom-[40%] animate-[spin_10s_linear_infinite] hidden lg:block"><img src="/images/shape/slide-shape-7.svg" alt="" /></div>
                <div className="absolute overflow-hidden top-[40%] lg:top-[0%] 2xl:top-[40%] left-[5%] lg:left-[2%] 2xl:left-[5%] hidden lg:block"><img src="/images/shape/slide-shape-5.svg" alt="" /></div>
                <div className="absolute overflow-hidden top-[40%] right-[40%]  hidden lg:block"><img src="/images/shape/slide-shape-2.svg" alt="" /></div>

                <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">

                    <div className="grid grid-cols-1 lg:grid-cols-12 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                        <div className="text-center lg:col-span-8 lg:text-start">

                            <div className="flex justify-center mb-5 lg:justify-start">
                                <a className="inline-flex items-center gap-x-2 bg-white border border-gray-200 lg:text-sm text-[10px] text-gray-800 p-1 pl-3 rounded-full transition hover:border-gray-300 " href="#">
                                    Elevate Your Potential: Ignite Success with Us!
                                    <span className="inline-flex items-center justify-center px-3 py-2 text-sm font-semibold text-white rounded-full gap-x-2 bg-regal-yellow ">
                                        <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </span>
                                </a>
                            </div>
                            <h1 className="block text-4xl font-bold text-gray-800 sm:text-5xl md:text-6xl lg:text-[81px] font-sans">Welcome to <span className="underline decoration-[8px] decoration-regal-yellow">Kavin’s Academy</span></h1>
                            <p className="mt-6 text-lg text-gray-600 lg:text-xl ">An Ultimate Destination fo
                                UPSC, TNPSC, IBPS, TET-TRB, SRD/DRB (Coop) Aspirants

                            </p>
                            <a className="mt-6 inline-flex items-center   lg:gap-x-2 font-semibold  bg-regal-yellow  border border-gray-200 lg:text-sm text-[12px] text-gray-800 p-2 pl-3 rounded-full transition hover:border-gray-300 ">
                                <svg className="text-regal-blue" xmlns="http://www.w3.org/2000/svg" height="2em" fill="currentColor" viewBox="0 0 640 512">  <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" /></svg>
                                BOTH ONLINE AND OFFLINE CLASSES AVAILABLE</a>
                        </div>


                        <div className="mt-10 lg:mt-0 lg:col-span-4">
                            <div className="flex flex-col transition bg-white border shadow-sm group rounded-xl hover:shadow-md " href="#">
                                <div className="p-4 my-5 md:p-5">
                                    <h1 className="mb-8 font-sans text-3xl font-bold text-center text-gray-800 md:text-3xl lg:text-4xl">Quick <span className="text-regal-yellow">Enquiry</span></h1>

                                    <form id="quickenquiryform" className="space-y-4 md:space-y-6">
                                        <div>
                                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your Name</label>
                                            <input type="text" id="email" name="Name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " required="" />
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your Mobile Number</label>
                                            <input type="text" id="email" name="Mobile Number" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required="" />
                                        </div>
                                        <button type="submit" className="w-full text-white bg-regal-blue hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

         <Coursesoffered/>

            {/* ============================================== Courses Offered ============================================ */}

            <section id="courses">
                <div className="max-w-[85rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    {/* <!-- Title --> */}
                    <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
                        <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">Courses Offered</h2>

                    </div>
                    <div className="grid items-center gap-6 text-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 md:gap-6">
                        <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
                            <img className="w-full h-auto rounded-t-xl" src="/images/courses/UPSC 1.jpg" alt="Image Description" />
                            <div className="p-4 md:p-5">

                                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                                    UPSC
                                </h3>
                                <a className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" href="#joinnow">
                                    Join Now
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
                            <img className="w-full h-auto rounded-t-xl" src="/images/courses/TNPSC1.jpg" alt="Image Description" />
                            <div className="p-4 md:p-5">

                                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                                    TNPSC
                                </h3>
                                <a className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" href="#joinnow">
                                    Join Now
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
                            <img className="w-full h-auto rounded-t-xl" src="/images/courses/BANK1.jpg" alt="Image Description" />
                            <div className="p-4 md:p-5">

                                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                                    IBPS
                                </h3>
                                <a className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" href="#joinnow">
                                    Join Now
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
                            <img className="w-full h-auto rounded-t-xl" src="/images/courses/TET1.jpg" alt="Image Description" />
                            <div className="p-4 md:p-5">
                                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                                    TET-TRB
                                </h3>
                                <a className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" href="#joinnow">
                                    Join Now
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
                            <img className="w-full h-auto rounded-t-xl" src="/images/courses/SRB-DRB.jpg" alt="Image Description" />
                            <div className="p-4 md:p-5">
                                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                                    SRB/DRB
                                </h3>
                                <a className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" href="#joinnow">
                                    Join Now
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
                            <img className="w-full h-auto rounded-t-xl" src="/images/courses/per.jpg" alt="Image Description" />
                            <div className="p-4 md:p-5">
                                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                                    PD & Soft Skills
                                </h3>
                                <a className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue" href="#joinnow">
                                    Join Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*============================================================= Why Kavin's Academy ==================================================== */}

            <section id="why-kavins-academy">
                <div className="max-w-[110rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    {/* <!-- Title --> */}
                    <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
                        <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">Why Kavin&apos;s Academy</h2>
                    </div>
                    <div className="grid items-center gap-6 text-center sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-6 md:gap-6">
                        <div className="w-full h-full max-w-sm p-6 border shadow-lg border-violet-200 rounded-xl bg-violet-50">
                            <div className="flex flex-col items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-violet-500">
                                <svg className="text-white w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512">
                                    {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                                    <path d="M15 15C24.4 5.7 39.6 5.7 49 15l63 63V40c0-13.3 10.7-24 24-24s24 10.7 24 24v96c0 13.3-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H78.1L15 49C5.7 39.6 5.7 24.4 15 15zM133.5 243.9C158.6 193.6 222.7 112 320 112s161.4 81.6 186.5 131.9c3.8 7.6 3.8 16.5 0 24.2C481.4 318.4 417.3 400 320 400s-161.4-81.6-186.5-131.9c-3.8-7.6-3.8-16.5 0-24.2zM320 320a64 64 0 1 0 0-128 64 64 0 1 0 0 128zM591 15c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-63 63H600c13.3 0 24 10.7 24 24s-10.7 24-24 24H504c-13.3 0-24-10.7-24-24V40c0-13.3 10.7-24 24-24s24 10.7 24 24V78.1l63-63zM15 497c-9.4-9.4-9.4-24.6 0-33.9l63-63H40c-13.3 0-24-10.7-24-24s10.7-24 24-24h96c13.3 0 24 10.7 24 24v96c0 13.3-10.7 24-24 24s-24-10.7-24-24V433.9L49 497c-9.4 9.4-24.6 9.4-33.9 0zm576 0l-63-63V472c0 13.3-10.7 24-24 24s-24-10.7-24-24V376c0-13.3 10.7-24 24-24h96c13.3 0 24 10.7 24 24s-10.7 24-24 24H561.9l63 63c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0z" /></svg>
                            </div>
                            <p className="mb-3 text-gray-500 ">Prepare students for exams with confidence through exam-focused Test Series</p>
                        </div>
                        <div className="w-full h-full max-w-sm p-6 border border-yellow-200 shadow-lg rounded-xl bg-yellow-50">
                            <div className="flex flex-col items-center justify-center w-16 h-16 mx-auto mb-3 bg-yellow-500 rounded-full">
                                <svg className="text-white w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
                                    {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                                    <path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z" /></svg>
                            </div>
                            <p className="mb-3 text-gray-500 ">Experienced faculty with a success rate of over 1000 students</p>
                        </div>
                        <div className="w-full h-full max-w-sm p-6 border border-green-200 shadow-lg rounded-xl bg-green-50">
                            <div className="flex flex-col items-center justify-center w-16 h-16 mx-auto mb-3 bg-green-500 rounded-full">
                                <svg className="text-white w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                                    {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                                    <path d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0H133.9c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0H487.4C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" /></svg>
                            </div>
                            <p className="mb-3 text-gray-500 ">Meticulously crafted study resources based on syllabus and past questions</p>
                        </div>
                        <div className="w-full h-full max-w-sm p-6 border border-blue-200 shadow-lg rounded-xl bg-blue-50" >
                            <div className="flex flex-col items-center justify-center w-16 h-16 mx-auto mb-3 bg-blue-500 rounded-full">
                                <svg className="text-white w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512">
                                    {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                                    <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H544c53 0 96-43 96-96V96c0-53-43-96-96-96H96zM64 96c0-17.7 14.3-32 32-32H544c17.7 0 32 14.3 32 32V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V96zm159.8 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM96 309.3c0 14.7 11.9 26.7 26.7 26.7h56.1c8-34.1 32.8-61.7 65.2-73.6c-7.5-4.1-16.2-6.4-25.3-6.4H149.3C119.9 256 96 279.9 96 309.3zM461.2 336h56.1c14.7 0 26.7-11.9 26.7-26.7c0-29.5-23.9-53.3-53.3-53.3H421.3c-9.2 0-17.8 2.3-25.3 6.4c32.4 11.9 57.2 39.5 65.2 73.6zM372 289c-3.9-.7-7.9-1-12-1H280c-4.1 0-8.1 .3-12 1c-26 4.4-47.3 22.7-55.9 47c-2.7 7.5-4.1 15.6-4.1 24c0 13.3 10.7 24 24 24H408c13.3 0 24-10.7 24-24c0-8.4-1.4-16.5-4.1-24c-8.6-24.3-29.9-42.6-55.9-47zM512 176a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM320 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128z" /></svg>
                            </div>
                            <p className="mb-3 text-gray-500 ">Motivational workshops by current and retired Civil Servants</p>
                        </div>
                        <div className="w-full h-full max-w-sm p-6 border border-orange-200 shadow-lg rounded-xl bg-orange-50">
                            <div className="flex flex-col items-center justify-center w-16 h-16 mx-auto mb-3 bg-orange-500 rounded-full">
                                <svg className="text-white w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512">
                                    {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                                    <path d="M192 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8 384V352h16V480c0 17.7 14.3 32 32 32s32-14.3 32-32V192h56 64 16c17.7 0 32-14.3 32-32s-14.3-32-32-32H384V64H576V256H384V224H320v48c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H368c-26.5 0-48 21.5-48 48v80H243.1 177.1c-33.7 0-64.9 17.7-82.3 46.6l-58.3 97c-9.1 15.1-4.2 34.8 10.9 43.9s34.8 4.2 43.9-10.9L120 256.9V480c0 17.7 14.3 32 32 32s32-14.3 32-32z" /></svg>
                            </div>
                            <p className="mb-3 text-gray-500 ">Promote nationalism and service through coaching and volunteer work</p>
                        </div>
                        <div className="w-full h-full max-w-sm p-6 border border-pink-200 shadow-lg rounded-xl bg-pink-50">
                            <div className="flex flex-col items-center justify-center w-16 h-16 mx-auto mb-3 bg-pink-500 rounded-full">
                                <svg className="text-white w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512">
                                    {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg>
                            </div>
                            <p className="mb-3 text-gray-500 ">Ensure comprehensive curriculum coverage with quality and timeliness</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* =============================================== slider section ========================================= */}

            <section className="bg-[url('/images/home/acheivements-bg.png')] bg-center bg-no-repeat overflow-hidden" id="our-faculties1">
                <div className="max-w-[110rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    <div className="slider">
                        <div className="min-h-[15rem] flex flex-col">
                            <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                                <svg className="max-w-[5rem]  text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512"><path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" /></svg>
                                <p className="text-2xl text-center text-gray-400">
                                    Interactive PPTs & Video Lectures
                                </p>
                            </div>
                        </div>
                        <div className="min-h-[15rem] flex flex-col">
                            <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                                <svg className="max-w-[5rem]  text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512"><path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" /></svg>
                                <p className="text-2xl text-center text-gray-400">
                                    Experienced Faculties
                                </p>
                            </div>
                        </div>
                        <div className="min-h-[15rem] flex flex-col">
                            <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">

                                <svg className="max-w-[5rem]  text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512"><path d="M160 96a96 96 0 1 1 192 0A96 96 0 1 1 160 96zm80 152V512l-48.4-24.2c-20.9-10.4-43.5-17-66.8-19.3l-96-9.6C12.5 457.2 0 443.5 0 427V224c0-17.7 14.3-32 32-32H62.3c63.6 0 125.6 19.6 177.7 56zm32 264V248c52.1-36.4 114.1-56 177.7-56H480c17.7 0 32 14.3 32 32V427c0 16.4-12.5 30.2-28.8 31.8l-96 9.6c-23.2 2.3-45.9 8.9-66.8 19.3L272 512z" /></svg>
                                <p className="text-2xl text-center text-gray-400">
                                    Personalized & Effective Learning
                                </p>
                            </div>
                        </div>
                        <div className="min-h-[15rem] flex flex-col">
                            <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                                <svg className="max-w-[5rem]  text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512"><path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" /></svg>

                                <p className="text-2xl text-center text-gray-400">
                                    Instant feedback & Assessment
                                </p>
                            </div>
                        </div>
                        <div className="min-h-[15rem] flex flex-col">
                            <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">

                                <svg className="max-w-[5rem]  text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512"><path d="M192 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8 384V352h16V480c0 17.7 14.3 32 32 32s32-14.3 32-32V192h56 64 16c17.7 0 32-14.3 32-32s-14.3-32-32-32H384V64H576V256H384V224H320v48c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H368c-26.5 0-48 21.5-48 48v80H243.1 177.1c-33.7 0-64.9 17.7-82.3 46.6l-58.3 97c-9.1 15.1-4.2 34.8 10.9 43.9s34.8 4.2 43.9-10.9L120 256.9V480c0 17.7 14.3 32 32 32s32-14.3 32-32z" /></svg>
                                <p className="text-2xl text-center text-gray-400">
                                    State-of-the-Art Classroom
                                </p>
                            </div>
                        </div>
                        <div className="min-h-[15rem] flex flex-col">
                            <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                                <svg className="max-w-[5rem]  text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512"><path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" /></svg>
                                <p className="text-2xl text-center text-gray-400">
                                    Flexible Online Classes
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*================================================= Our Faculties =============================================*/}
            <section className="relative overflow-hidden" id="our-faculties">
                <div className="max-w-[110rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    {/* <!-- Title --> */}
                    <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
                        <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">Our Faculties</h2>
                        <p className="mt-1 text-lg text-gray-600 lg:text-xl">Our faculty members have chiselled and crafted more than thousand
                            student aspirants to realise their dream of becoming a civil servant.</p>
                    </div>
                    <div className="grid items-center grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:gap-10">
                        <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
                            <img className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl" src="/images/ourteam/Valan-web.jpg" alt="Image Description" />
                            <div className="max-w-[16rem] w-full h-full relative -top-7  lg:-top-10 flex flex-auto flex-col justify-center items-center p-3 bg-gray-800  rounded-2xl mx-auto shadow-sm">
                                <div className="absolute inset-0 z-10 "></div>
                                <div className="relative z-20 text-center">
                                    <h3 className="mb-2 font-bold text-white">
                                        Dr. VALAN SUBARAJA J A
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
                            <img className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl" src="/images/ourteam/rajesh-web.jpg" alt="Image Description" />
                            <div className="max-w-[16rem] w-full h-full relative -top-7 lg:-top-10 flex flex-auto flex-col justify-center items-center p-3 bg-gray-800  rounded-2xl mx-auto shadow-sm">
                                <div className="absolute inset-0 z-10 "></div>
                                <div className="relative z-20 text-center">
                                    <h3 className="mb-2 font-bold text-white ">
                                        Mr. RAJESH ALAGUMANI
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
                            <img className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl" src="/images/ourteam/duraish-Web.jpg" alt="Image Description" />
                            <div className="max-w-[16rem] w-full h-full relative -top-7 lg:-top-10 flex flex-auto flex-col justify-center items-center p-3  bg-gray-800  rounded-2xl mx-auto shadow-sm">
                                <div className="absolute inset-0 z-10 "></div>
                                <div className="relative z-20 text-center">
                                    <h3 className="mb-2 font-bold text-white ">
                                        Mr. M V DURAISH KUMAR
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
                            <img className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl" src="/images/ourteam/dinesh swamy prasanna - Web.jpg" alt="Image Description" />
                            <div className="max-w-[16rem] w-full h-full relative -top-7 lg:-top-10 flex flex-auto flex-col justify-center items-center p-3  bg-gray-800  rounded-2xl mx-auto shadow-sm">
                                <div className="absolute inset-0 z-10 "></div>
                                <div className="relative z-20 text-center">
                                    <h3 className="mb-2 font-bold text-white ">
                                        Mr. DINESH SWAMY PRASANNA N
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
                            <img className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl" src="/images/ourteam/Venkat-Web.jpg" alt="Image Description" />
                            <div className="max-w-[16rem] w-full h-full relative -top-7 lg:-top-10 flex flex-auto flex-col justify-center items-center p-3  bg-gray-800  rounded-2xl mx-auto shadow-sm">
                                <div className="absolute inset-0 z-10 "></div>
                                <div className="relative z-20 text-center">
                                    <h3 className="mb-2 font-bold text-white ">
                                        Mr. VENKAT
                                    </h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*================================================= Students review ========================================= */}

            <section className="bg-[url('/images/home/bigger-bg.jpg')] bg-cover overflow-hidden" id="students-feedback">
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
                        <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">Students Feedback</h2>
                        <p className="mt-1 text-lg text-gray-600 lg:text-xl"> A Students Review for Kavin’s Academy</p>
                    </div>
                    <div className="slider1">
                        <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
                            <figcaption className="flex items-center space-x-4">
                                <span className="relative  inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                                    <span className="text-lg font-medium leading-none text-white">G</span>
                                </span>
                                <div className="flex-auto"><div className="text-base font-semibold text-slate-900 ">
                                    <a href="#!" tabIndex="0">
                                        <span className="absolute inset-0"></span>Ganesh</a>
                                </div>
                                    <div className="mt-0.5">Student</div>
                                </div>
                            </figcaption>
                            <blockquote className="mt-6 text-slate-700 ">
                                <p>Incredible coaching, boosted my confidence, and helped me excel in exams.</p>
                            </blockquote>
                        </figure>
                        <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
                            <figcaption className="flex items-center space-x-4">
                                <span className="inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                                    <span className="text-lg font-medium leading-none text-white">A</span>
                                </span>
                                <div className="flex-auto"><div className="text-base font-semibold text-slate-900 ">
                                    <a href="#!" tabIndex="0">
                                        <span className="absolute inset-0"></span>Awasthi</a>
                                </div>
                                    <div className="mt-0.5">Student</div>
                                </div>
                            </figcaption>
                            <blockquote className="mt-6 text-slate-700 ">
                                <p>Top-notch instructors, comprehensive study materials - the perfect recipe for success!</p>
                            </blockquote>
                        </figure>
                        <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
                            <figcaption className="flex items-center space-x-4">
                                <span className="inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                                    <span className="text-lg font-medium leading-none text-white">R</span>
                                </span>
                                <div className="flex-auto"><div className="text-base font-semibold text-slate-900 ">
                                    <a href="#!" tabIndex="0">
                                        <span className="absolute inset-0"></span>R. Gayathri</a>
                                </div>
                                    <div className="mt-0.5">Student</div>
                                </div>
                            </figcaption>
                            <blockquote className="mt-6 text-slate-700 ">
                                <p>Life-changing experience! The coaching center turned my weaknesses into strengths.</p>
                            </blockquote>
                        </figure>
                        <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
                            <figcaption className="flex items-center space-x-4">
                                <span className="inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                                    <span className="text-lg font-medium leading-none text-white">P</span>
                                </span>
                                <div className="flex-auto"><div className="text-base font-semibold text-slate-900 ">
                                    <a href="#!" tabIndex="0">
                                        <span className="absolute inset-0"></span>Priya</a>
                                </div>
                                    <div className="mt-0.5">Student</div>
                                </div>
                            </figcaption>
                            <blockquote className="mt-6 text-slate-700 ">
                                <p>Exceptional support, motivating workshops, and personalized guidance made all the difference.</p>
                            </blockquote>
                        </figure>
                        <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
                            <figcaption className="flex items-center space-x-4">
                                <span className="inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                                    <span className="text-lg font-medium leading-none text-white">R</span>
                                </span>
                                <div className="flex-auto"><div className="text-base font-semibold text-slate-900 ">
                                    <a href="#!" tabIndex="0">
                                        <span className="absolute inset-0"></span>Rahul</a>
                                </div>
                                    <div className="mt-0.5">Student</div>
                                </div>
                            </figcaption>
                            <blockquote className="mt-6 text-slate-700 ">
                                <p>A game-changer! This coaching center&apos;s dedication to excellence is unmatched.
                                </p>
                            </blockquote>
                        </figure>
                    </div>
                </div>
            </section>

            {/*============================================= Join Now =========================================*/}
            <section id="joinnow">
                <div className="max-w-[85rem] overflow-hidden w-full h-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    <div className="grid items-center grid-cols-1 gap-6 md:gap-10">
                        <div className="bg-[url('/images/news-bg.png')] bg-center bg-no-repeat rounded-2xl flex justify-center items-center px-4 py-15 sm:px-6 lg:px-8 lg:py-14">
                            <div className="flex flex-col shadow-slate-900/[.7] bg-slate-900/[.7] shadow-sm rounded-xl max-w-full lg:max-w-[40rem] lg:max-h-[40rem] w-full h-full">
                                <div className="p-5 text-center md:p-10">
                                    <div className="hidden mb-5">
                                        <img src="./assets/images/logo/favicon.png" alt="" className="w-auto h-10 lg:h-16" />
                                    </div>
                                    <h3 className="text-3xl font-extrabold lg:text-5xl text-regal-yellow">
                                        Join Now
                                    </h3>
                                    <form className="mt-5" id="joinnowform">
                                        <div className="grid grid-flow-row-dense grid-cols-4 gap-4">
                                            <div className="col-span-full lg:col-span-2">
                                                <label htmlFor="fullname" className="block mb-2 text-sm text-white text-start">Full Name</label>
                                                <div className="relative">
                                                    <input type="text" id="fullname" name="Name" className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md pl-11 focus:border-regal-yellow focus:ring-regal-yellow" required aria-describedby="fullname-error" />
                                                    <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                                                        <svg className="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-full lg:col-span-2">
                                                <label htmlFor="email" className="block mb-2 text-sm text-white text-start">Email address</label>
                                                <div className="relative">
                                                    <input type="email" id="email" name="Email" className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md pl-11 focus:border-regal-yellow focus:ring-regal-yellow" required aria-describedby="email-error" />
                                                    <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                                                        <svg className="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-full">
                                                <label htmlFor="mobile" className="block mb-2 text-sm text-white text-start">Mobile Number</label>
                                                <div className="relative">
                                                    <input type="text" id="mobile" name="Mobile Number" className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md pl-11 focus:border-regal-yellow focus:ring-regal-yellow" required aria-describedby="mobile-error" />
                                                    <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                                                        <svg className="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 384 512"><path d="M80 0C44.7 0 16 28.7 16 64V448c0 35.3 28.7 64 64 64H304c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H80zm80 432h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z" /></svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-full">
                                                <label htmlFor="course" className="block mb-2 text-sm text-white text-start">Select a Course</label>
                                                <div className="relative">
                                                    <select id="course" name="Course Name" className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md pl-11 focus:border-regal-yellow focus:ring-regal-yellow" required>
                                                        <option value="" selected></option>
                                                        <option value="UPSC">UPSC</option>
                                                        <option value="TNPSC">TNPSC</option>
                                                        <option value="IBPS">IBPS</option>
                                                        <option value="TET-TRB">TET-TRB</option>
                                                        <option value="SRB/DRB">SRB/DRB</option>
                                                        <option value="PD & Soft Skills">PD & Soft Skills</option>
                                                    </select>
                                                    <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                                                        <svg className="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 640 512"><path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" /></svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-full">
                                                <label htmlFor="message" className="block mb-2 text-sm text-white text-start">Message</label>
                                                <div className="relative">
                                                    <textarea id="message" name="Message" className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500" required aria-describedby="message-error"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-span-full">
                                                <div className="relative">
                                                    <button type="submit" className="inline-flex items-center justify-center w-full gap-2 px-4 py-3 text-sm font-semibold text-white transition-all border border-transparent rounded-md bg-regal-yellow hover:bg-regal-blue focus:outline-none focus:ring-2 focus:bg-regal-blue focus:ring-offset-2">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a href="https://wa.me/9791551876?text=Hello%20I%20want%20to%20enquire%20about%20your%20services" target="_blank">
                <button>
                    <Tooltip color="success" content="Contact on Whatsapp Now!" placement="left-start" showArrow={true}>
                        <img src="/images/whatsapp.png" alt="" className="fixed bottom-24 right-5 w-14 h-14 animate-bounce-up-down" />
                    </Tooltip>
                </button>
            </a>

            <button id="to-top-button" title="Go To Top"
                className="fixed z-50 hidden p-4 text-lg font-semibold text-white transition-colors duration-300 border-0 rounded-full shadow-md bottom-5 right-5 w-14 h-14 bg-regal-blue hover:bg-regal-yellow"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                >
                    <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
                </svg>
            </button>
            
        </>
    )
}