import { useEffect } from "react";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


export default function Coursesoffered() {

    useEffect(() => {
       
            new Swiper(".mySwiper", {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                autoplay: {
                    delay: 3000,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                breakpoints: {
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                },
            });
      
    }, []);

    return (
        <>
            <section id="ongoingcourses">
                <div className="max-w-[70rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    {/* <!-- Title --> */}
                    <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
                        <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">Ongoing Classes</h2>

                    </div>
                    <div className="grid items-center grid-cols-1 gap-6 text-center lg:grid-cols-2 md:gap-6">
                        <div className="w-full h-full swiper mySwiper col-span-full">
                            <div className="swiper-wrapper ">
                                <div className="swiper-slide ">
                                    <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                        <div >
                                            <div className="px-6 py-6 bg-yellow-500">
                                                <h1>Ongoing</h1>
                                            </div>
                                            <div className="p-2 text-white bg-gray-800">
                                                2024
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <h1 className="text-xl font-semibold">Group I & II</h1>
                                            <h1 className="mb-3 text-lg">Weekly Mentorship program</h1>
                                            <p className="text-sm">Class Timing : 5 PM to 7 PM (Mon- Fri)</p>
                                            <p className="text-sm">Test Timing : Sunday 10 AM</p>
                                            <p className="text-sm">Test Discussion: Sunday 2 PM to 4 PM</p>
                                        </div>
                                    </div></div>
                                <div className="swiper-slide">
                                    <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                        <div>
                                            <div className="px-6 py-6 bg-yellow-500">
                                                <h1>Ongoing</h1>
                                            </div>
                                            <div className="p-2 text-white bg-gray-800">
                                                2024
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <h1 className="text-xl font-semibold">Group IV Morning batch</h1>
                                            <h1 className="mb-3 text-lg">Weekly Mentorship program</h1>
                                            <p className="text-sm">Class Timing : 5 PM to 7 PM (Mon- Fri)</p>
                                            <p className="text-sm">Test Timing : Sunday 10 AM</p>
                                            <p className="text-sm">Test Discussion: Sunday 2 PM to 4 PM</p>
                                        </div>
                                    </div></div>
                                <div className="swiper-slide">
                                    <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                        <div>
                                            <div className="px-6 py-6 bg-yellow-500">
                                                <h1>Ongoing</h1>
                                            </div>
                                            <div className="p-2 text-white bg-gray-800">
                                                2024
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <h1 className="text-xl font-semibold">DRB/SRB Morning batch</h1>
                                            <h1 className="mb-3 text-lg">Weekly Mentorship program</h1>
                                            <p className="text-sm">Class Timing : 10.00 AM to & 01.00 PM (Tue- Sat)</p>
                                            <p className="text-sm">Test Timing : Sunday 10 AM</p>
                                            <p className="text-sm">Test Discussion: Sunday 2 PM to 4 PM</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">

                                    <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                        <div>
                                            <div className="px-6 py-6 bg-yellow-500">
                                                <h1>Ongoing</h1>
                                            </div>
                                            <div className="p-2 text-white bg-gray-800">
                                                2024
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <h1 className="mb-3 text-xl font-semibold">TRB- TET Paper II batch</h1>

                                            <p className="text-sm">Class Timing : 10.00 AM to 5.00 PM (Every Sunday)</p>
                                            <p className="text-sm">Including Material</p>
                                            <p className="text-sm">Including Test Series with Test Discussion</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="upcomingcourses" className=" bg-slate-50">
                <div className="max-w-[70rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                    {/* <!-- Title --> */}
                    <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
                        <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">Upcoming Classes</h2>

                    </div>
                    <div className="grid items-center grid-cols-1 gap-6 text-center lg:grid-cols-2 md:gap-6">
                        <div className="w-full h-full swiper mySwiper col-span-full">
                            <div className="swiper-wrapper ">
                                <div className="swiper-slide ">
                                    <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                        <div >
                                            <div className="px-6 py-6 bg-yellow-500">
                                                <h1>Mar 20</h1>
                                            </div>
                                            <div className="p-2 text-white bg-gray-800">
                                                2024
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <h1 className="text-xl font-semibold">Group IV Evening batch</h1>
                                            <h1 className="mb-3 text-lg">Weekly Mentorship program</h1>
                                            <p className="text-sm">Class Timing : 7.00 PM to 9.00 PM (Mon- Fri)</p>
                                            <p className="text-sm">Test Timing : Sunday 10 AM</p>
                                            <p className="text-sm">Test Discussion: Sunday 2 PM to 4 PM</p>
                                        </div>
                                    </div></div>
                                <div className="swiper-slide">
                                    <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                        <div>
                                            <div className="px-6 py-6 bg-yellow-500">
                                                <h1>Mar 16</h1>
                                            </div>
                                            <div className="p-2 text-white bg-gray-800">
                                                2024
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <h1 className="text-xl font-semibold">Group IV Crash course</h1>
                                            <h1 className="mb-3 text-lg">Weekly Mentorship program</h1>
                                            <p className="text-sm">Class Timing : 10.00 AM to 5.00 PM (Sat & Sun)</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">

                                    <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                        <div>
                                            <div className="px-6 py-6 bg-yellow-500">
                                                <h1>Mar 20</h1>
                                            </div>
                                            <div className="p-2 text-white bg-gray-800">
                                                2024
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <h1 className="text-xl font-semibold">DRB/SRB Evening batch</h1>
                                            <h1 className="mb-3 text-lg">Weekly Mentorship program</h1>
                                            <p className="text-sm">Class Timing : 5.00 PM to & 7.00 PM (Mon- Fri)</p>
                                            <p className="text-sm">Test Timing : Sunday 10 AM</p>
                                            <p className="text-sm">Test Discussion: Sunday 2 PM to 4 PM</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">

                                    <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                        <div>
                                            <div className="px-6 py-6 bg-yellow-500">
                                                <h1>Jun 16</h1>
                                            </div>
                                            <div className="p-2 text-white bg-gray-800">
                                                2024
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <h1 className="text-xl font-semibold">UPSC Morning batch</h1>
                                            <h1 className="mb-3 text-lg">Weekly Mentorship program</h1>
                                            <p className="text-sm">Class Timing : 10.00 AM to & 1.00 PM (Mon- Fri)</p>
                                            <p className="text-sm">Course : Prelims cum Mains + CSAT</p>
                                            <p className="text-sm">Including Test Series</p>
                                            <p className="text-sm">Optionals :On Saturday & Sunday</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="flex flex-wrap items-center h-full gap-6 p-5 bg-white border rounded-md shadow lg:flex-nowrap">
                                        <div>
                                            <div className="px-6 py-6 bg-yellow-500">
                                                <h1>Jun 16</h1>
                                            </div>
                                            <div className="p-2 text-white bg-gray-800">
                                                2024
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <h1 className="text-xl font-semibold">UPSC Evening batch</h1>
                                            <h1 className="mb-3 text-lg">Weekly Mentorship program</h1>
                                            <p className="text-sm">Class Timing : 5.00 PM to 7.00 PM (Mon- Fri)</p>
                                            <p className="text-sm">Course : Prelims cum Mains + CSAT</p>
                                            <p className="text-sm">Including Test Series</p>
                                            <p className="text-sm">Optionals :On Saturday & Sunday</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
