/* eslint-disable react/prop-types */
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";

export default function Customtable(props) {
  const { data, editform, confirm2, handlepdfview, handleVideoView } = props;


  return (
    <div className="overflow-auto bg-white border rounded-xl h-[400px] xl:h-[500px] 2xl:h-[700px] my-5">
      <table className="min-w-full divide-y divide-gray-200 rounded-xl">
        <thead >
          <tr>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">S.no</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Subject Title</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Course Name</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Course Pdf</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Course Video</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Status</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Action</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data?.resdata?.map((item, index) =>
            <tr key={index}>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{index + 1}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Course_Title}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Course_Name}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500"><span className="flex gap-1 cursor-pointer" onClick={async () => await handlepdfview(item._id)}><i className="text-xl text-red-500 fi fi-sr-file-pdf"></i> <span className="mt-1">View</span></span></td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500"><span className="flex gap-1 cursor-pointer" onClick={async () => await handleVideoView(item.Course_Video)}> <i className="text-xl text-red-500 fi fi-brands-youtube"></i> <span className="">Play</span></span></td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Status}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                <Dropdown>
                  <DropdownTrigger>
                    <i className="text-lg cursor-pointer fi fi-rr-menu-dots-vertical"></i>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Dynamic Actions" >
                    <DropdownItem onPress={() => editform(item)} color={"default"}>
                      Edit
                    </DropdownItem>
                    <DropdownItem onPress={() => confirm2(item._id)} color={"danger"} className={"text-danger"}>
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}