import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../services/store/useAuth";
import { apilogin } from "../../services/apiauthentication/apilogin";
import toast from "react-hot-toast";

export default function Loginform() {

    const {login}=useAuth();
    const navigate = useNavigate();
    const [formdata, setformdata] = useState({});
    const handlechange = (e) => setformdata({ ...formdata, [e.target.name]: e.target.value });

    const handlelogin = async (e) => {
        e.preventDefault();
        try {
            const res = await apilogin(formdata);
            console.log(res);
            if (res?.message === "Login successful") {
                toast.success("Login successful");
                login(res.token); 
                navigate('/dashboard');
            } else {
                toast.error(res?.message || "Invalid username or password");
            }
        } catch (error) {
            toast.error("An error occurred during login");
            console.error("Login error:", error);
        }
    };
    

    return (
        <div className="flex items-center justify-center h-dvh ">
            <div className="max-w-[25rem] mx-auto w-full px-4 ">
                <div className="p-8 bg-white border shadow-xl rounded-xl">
                    <form onSubmit={handlelogin}>
                        <div className="flex justify-center mt-1 mb-7">
                            <img src="/images/logo/KAVINS ACADEMY logo.png" alt="" className="h-[40px] object-cover" />
                        </div>
                        <div className="mb-3">
                            <label className="text-lg">Email</label>
                            <div className="mt-1">
                                <input type="text" name="Username" id="Username" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="text-lg">Password</label>
                            <div className="mt-1">
                                <input type="password" name="Password" id="Password" className="w-full px-4 py-2 border outline-none rounded-xl" onChange={handlechange} required />
                            </div>
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue">
                                Sign in
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}