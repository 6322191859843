import { useState } from 'react';
const useForm = (initialValues) => {
    const [formdata,setformdata]=useState(initialValues);
    const handlechange=(e)=>e.target.files?setformdata({...formdata,...{[e.target.name]:e.target.files[0]}}):setformdata({...formdata,[e.target.name]:e.target.value});
    const [visible, setVisible] = useState(false);
    const openform=()=>{
        setformdata({});
        setVisible(true)
    }
    const editform=(data)=>{
        setformdata({});
        setformdata(data);
        setVisible(true);
    }
    const resetForm = () => {
        setformdata(initialValues);
        setVisible(false);
    }
    return {formdata,handlechange,visible, setVisible,openform,editform,resetForm};
};
export default useForm;