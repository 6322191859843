import { Routes, Route, Navigate } from "react-router-dom";
import AdminMain from "../core/Main";
import Dashboardpage from "../components/DashboardPage";
import Courses from "../components/Courses";
import Applicationrecevpage from "../components/ApplicationrecevPage";
import StudentsPage from "../components/StudentsPage";
import Onandupcomeclasspage from "../components/OnandUpcomeclassPage";
import StudentCoursePage from "../components/StudentDashboard/StudentCoursePage";
import CourseDetails from "../shared/components/StudentDashboard/StudentCourses/CourseDetails";
import Studenthome from "../shared/components/StudentDashboard/StudentCourses/StudentHome";
import useAuth from "../../shared/services/store/useAuth";

const AdminRouter = () => {
  const { userdetails } = useAuth();

  const getRedirectPath = () => {
    if (userdetails?.Role === "Student") {
      return "/dashboard/studenthome";
    }
    return "/dashboard/home";
  };
  return (
    <Routes>
      <Route element={<AdminMain />}>
        <Route path="home" element={<Dashboardpage />} />
        <Route path="studenthome" element={<Studenthome/>}/>
        <Route path="courses" element={<Courses />} />
        <Route path="applications" element={<Applicationrecevpage/>} />
        <Route path="students" element={<StudentsPage/>} />
        <Route path="onandupcomeclass" element={<Onandupcomeclasspage/>}/>
        <Route path="studentcourses" element={<StudentCoursePage/>} />
        <Route path="course/:courseId" element={<CourseDetails/>} />

        <Route path="/" element={<Navigate to={getRedirectPath()} replace />} />
      </Route>
    </Routes>
  );
};

export default AdminRouter;
