import axios from "axios";
import { gettoken } from "../token/token";
import apiurl from "../../../admin/shared/services/apiendpoint/apiendpoint";

export const apisaveStudApplication = async (datas) => {
    try {
        const formData = new FormData();
        for (const key in datas) {
            formData.append(key, datas[key]);
        }
        const token = gettoken();
        const res = await axios.post(`${apiurl()}/application/apisaveStudapplication`, formData, { headers: { "Authorization": `Bearer ${token}` }});

        return res.data;
    } catch (err) {
        console.log(err);
    }
};