import { Outlet } from "react-router-dom";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";

export default function AdminMain() {
  return (
    <>
       <Topbar/>
        <Sidebar/>
        <main className='bg-slate-50 h-dvh'>
          <div className="w-full lg:ps-20">
             <div className="p-4 space-y-4 sm:p-6 sm:space-y-6">
                <Outlet/>
            </div>
         </div>
        </main>
    </>
  )
}
