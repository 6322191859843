import { useEffect } from "react";

export default function Ourgallery() {

    useEffect(() => {
        const toTopButton = document.getElementById("to-top-button");
    
        const handleScroll = () => {
          if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            toTopButton.classList.remove("hidden");
          } else {
            toTopButton.classList.add("hidden");
          }
        };
    
        const handleClick = () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        };
    
        if (toTopButton) {
          window.addEventListener("scroll", handleScroll);
          toTopButton.addEventListener("click", handleClick);
        }
        return () => {
          window.removeEventListener("scroll", handleScroll);
          if (toTopButton) {
            toTopButton.removeEventListener("click", handleClick);
          }
        };
      }, []);

    return (
        <>
            <section className="bg-[url('/images/about/bg-img.jpg')] bg-center bg-no-repeat overflow-hidden">
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-17 mx-auto">
                    <div className="grid grid-cols-1 gap-6 text-center md:gap-6">
                        <div>
                            <h1 className="mb-3 text-3xl font-extrabold text-white lg:text-5xl">Our Gallery</h1>

                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-12 mx-auto">
                    <div className="grid grid-cols-1 gap-6 md:gap-6">
                        <h1 className="mb-5 text-2xl font-extrabold">Inauguration</h1>
                    </div>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 md:gap-6">
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (1).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (2).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (3).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (4).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (5).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (6).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (7).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (8).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (9).jpg" alt="" />
                        </div>
                        <div className="relative overflow-hidden bg-no-repeat bg-cover border rounded-lg shadow">
                            <img className="h-auto max-w-full transition duration-300 ease-in-out hover:scale-110" src="/images/gallery/001 (10).jpg" alt="" />
                        </div>

                    </div>

                </div>
            </section>
            <button id="to-top-button" title="Go To Top"
                className="fixed z-50 hidden p-4 text-lg font-semibold text-white transition-colors duration-300 border-0 rounded-full shadow-md bottom-5 right-5 w-14 h-14 bg-regal-blue hover:bg-regal-yellow">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
                </svg>

            </button>
        </>
    )
}