import { useQueryClient } from "@tanstack/react-query";
import useForm from "../../admin/hooks/useForm";
import ApplicationForm from "../../shared/components/Home/Application-form";
import { apisaveStudApplication } from "../../shared/services/apistudapplication/apistudApplication";
import toast from "react-hot-toast";

export default function ApplicationformPage(initialValues) {

    const queryClient = useQueryClient();
    const { formdata, handlechange, resetForm } = useForm(initialValues);

    const handlesave = async (e) => {
        e.preventDefault();
        const res = await apisaveStudApplication(formdata);
        console.log(res);
        if (res.message === "Successfully saved") {
            handlesuccess(res);
        } else {
            toast.error(res.message);
        }
    };

    const handlesuccess = (res) => {
        toast.success(res.message)
        queryClient.invalidateQueries('studentdata');
        resetForm();
    }

    return (
        <ApplicationForm
            formdata={formdata}
            handlesave={handlesave}
            handlechange={handlechange}
        />
    )
}